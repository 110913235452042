import React from 'react';
import { observer } from 'mobx-react';
import { Tab } from 'react-bootstrap';
import classNames from 'classnames';

@observer
class ScrollTabPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { children, eventKey, activeKey, className, ...props } = this.props;

    return (
      <Tab.Pane
        eventKey={eventKey}
        active={eventKey == activeKey}
        className={classNames('h-100', className)}
        {...props}
      >
        {children}
      </Tab.Pane>
    );
  }
}

ScrollTabPanel.propType = {};

export { ScrollTabPanel };
