import dayjs from 'dayjs';
import { NotificationManager } from 'react-notifications';

export const isEmptyObj = (obj) => {
  if (!obj) return true;
  return obj.constructor === Object && Object.keys(obj).length === 0;
};

export const decapitalize = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export function getObjectPath(obj, path) {
  return path.split('.').reduce((acc, key) => acc && acc[key], obj);
}

export function setObjectPath(obj, path, value) {
  const keys = path.split('.');
  const lastKey = keys.pop(); // 마지막 키
  const targetObj = keys.reduce((acc, key) => (acc[key] = acc[key] || {}), obj); // 마지막 단계까지 객체 생성
  targetObj[lastKey] = value;
}

export function extractTextFromElement(element) {
  if (typeof element === 'string' || typeof element === 'number') {
    return element;
  }

  if (typeof element === 'object') {
    const children = element.props.children;

    if (Array.isArray(children)) {
      return children.map(extractTextFromElement).join('');
    }

    return extractTextFromElement(children);
  }

  return '';
}

export function filterValidValues(obj) {
  const result = {};
  if (!obj) return result;
  for (let key in obj) {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      result[key] = obj[key];
    }
  }
  return result;
}

export function optionsLabelEnum(options, value) {
  return options?.find((v) => v.value === value)?.label;
}
export function optionsValueEnum(options, label) {
  return options?.find((v) => v.label === label)?.value;
}

export function disableFutureDates(c) {
  return c > dayjs().endOf('day');
}

export function isNumericString(str) {
  if (!str) return false;
  return /^[0-9]+$/.test(str);
}

export const handleCopyClipBoard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    NotificationManager.info('클립보드에 복사되었습니다.');
  } catch (e) {
    NotificationManager.info('복사에 실패하였습니다');
  }
};

export function formatPhoneNumber(phoneNumber, notNumReturn = '') {
  if (!isNumericString(phoneNumber)) return notNumReturn;
  return String(phoneNumber).replace(
    /^(02|\d{3})(\d{3,4})(\d{4})$/,
    '$1-$2-$3',
  );
}
