import React from 'react';
import Styles from './ChannelList.module.scss';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Channel } from './Channel.js';
import { NoChannel } from './NoChannel.js';
import { TicketStore } from '../../store/TicketStore.js';

@observer
class TicketList extends React.Component {
  // componentDidMount() {
  //     let {store} = this.props;
  // }

  render() {
    let {
      store,
      chatStore,
      onChannelClick,
      header,
      footer,
      isAgent = false,
      renderLabel,
      onCloseCallback,
      activeChannelId,
    } = this.props;

    const typings = (id) => {
      return (
        chatStore?.typingUser?.size > 0 &&
        !!chatStore?.typingUsers.find((v) => v?.channelId === id)
      );
    };

    return (
      <div className={Styles.Channel}>
        {header && header}
        <div className={Styles.Scroll}>
          {store?.tickets?.length > 0 ? (
            store?.tickets?.map((channel) => (
              <Channel
                key={channel.id}
                channel={channel}
                store={store}
                onChannelClick={onChannelClick}
                isAgent={isAgent}
                renderLabel={renderLabel}
                onCloseCallback={onCloseCallback}
                activeChannelId={activeChannelId}
                isTypingUser={typings(channel.id)}
              />
            ))
          ) : (
            <NoChannel />
          )}
        </div>
        {footer}
      </div>
    );
  }
}

TicketList.propType = {
  store: PropTypes.instanceOf(TicketStore),
  onChannelClick: PropTypes.func,
  header: PropTypes.element,
};

export { TicketList };
