import React from 'react';
import { TICKET_STATUS } from '../../const/ChatConst';
import { Tag } from 'antd';

export const ChannelStatus = ({ status, ...props }) => {
  if (status === TICKET_STATUS.CLOSED)
    return (
      <Tag
        style={{
          color: 'var(--qt-gray4)',
          background: 'var(--qt-gray6)',
        }}
        bordered={false}
        {...props}
      >
        종료
      </Tag>
    );
  if (status === TICKET_STATUS.ACTIVE)
    return (
      <Tag color="processing" {...props}>
        진행중
      </Tag>
    );

  return <></>;
};
